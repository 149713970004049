<template>
    <article class="mentor-card">

        <div class="contenedor-imagen">
            <span class="imagen"
                :style="(data.image_url) ? `background:url(${data.image_url}) center/cover no-repeat` : null"></span>
        </div>
        <h2 class="nombre">{{ data.name }} {{ data.surname }} {{ data.surname_2 }}</h2>
        <h3>{{ data.profesional_current }}</h3>
        <h4>{{ data.profesional_current_company }}</h4>
        <h4>{{ data.province }}</h4>

        <a :href="data.profesional_linkedin" class="linkedin" v-if="data.profesional_linkedin">
            <i class="fa fa-linkedin"></i>
            @linkedin
        </a>

        <p>{{ data.profesional_resumen }}</p>

        <p>
            <strong>Áreas profesionales:</strong>
            <template v-for="(n, key) in data.area">
                {{ n }}<template v-if="key != data.area.length - 1">;</template>
            </template>
            <span v-if="data.area == 0">Sin definir</span>
        </p>

        <p>
            <strong>Sectores:</strong>
            <template v-for="(n, key) in data.sector">
                {{ n }}<template v-if="key != data.sector.length - 1">;</template>
            </template>
            <span v-if="data.sector == 0">Sin definir</span>
        </p>


        <p>
            <strong>Habilidades:</strong>
            <template v-for="(n, key) in data.ability">
                {{ n }}<template v-if="key != data.ability.length - 1">;</template>
            </template>
            <span v-if="data.ability == 0"> Sin definir</span>
        </p>


        <p><strong>Modalidad de mentoría:</strong> {{ domains.preferences[data.preferences_participation] }}</p>

        <router-link :to="$t('links.fichamentor') + '/' + data.id" class="enlace-cover" target="_blank"></router-link>

        <a class="btn -small" v-if="!hidematch && data.programs.length == 1" @click="requestMatch"
            :loading="loading">Hacer match</a>
        <a class="btn -small" v-if="!hidematch && data.programs.length != 1" @click="showpopupmatch = true">Hacer match
        </a>
        <router-link class="btn -skull-orange -small" :to="$t('links.mismentorias')" v-if="hidematch">Ver mentoría
        </router-link>

        <div class="cover-match" v-if="showpopupmatch">
            <dv class="popup formulario">
                <h2>Hacer Match</h2>
                <p>Por favor, selecciona el programa sobre el que quieres realizar tu mentoría.</p>
                <div class="contenedor-input">
                    <select v-model="programselected">
                        <option :value="null">Selecciona un programa</option>
                        <option :value="program.id" :key="key" v-for="(program, key) in data.programs">{{ program.name
                        }}
                        </option>
                    </select>
                </div>
                <div class="contenedor-boton">
                    <a @click="requestMatch" class="btn -dark-orange" :disabled="programselected == null">Hacer
                        match</a>
                    <a @click="cancelpopup" class="btn -skull-blue">Cancelar</a>

                </div>
            </dv>
        </div>



    </article>
</template>


<script>

import { mapGetters, mapActions } from 'vuex'


export default {
    name: 'mentor-card',
    props: ['data'],
    data: () => ({
        programselected: null,
        loading: false,
        hidematch: false,
        showpopupmatch: false
    }),

    methods: {
        ...mapActions(['createMentoria']),
        requestMatch() {
            let t = this;
            this.$swal({
                title: 'Aviso',
                html: "<p>Estás a punto de iniciar una mentoría con este mentor/a, quien en breve contactará contigo.<br>  ¿Quieres continuar con el match?</p>",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#38536e',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí',
                cancelButtonText: 'No'
            }).then(function (isConfirm) {
                if (isConfirm.value === true) {
                    t.enviar();
                }
            });

        },
        enviar() {
            if (this.data.programs.length == 1) {
                this.programselected = this.data.programs[0].id;
            }

            let data = {
                profesor: this.data.id,
                program: this.programselected,
            }

            this.loading = true;

            this.createMentoria(data).then(r => {
                if (r) {
                    this.hidematch = true;
                    this.cancelpopup();
                }
                this.loading = false;
            })
        },
        cancelpopup() {
            this.showpopupmatch = false;
        }
    },
    computed: {
        ...mapGetters({
            domains: 'getDomains'
        })
    }
}

</script>