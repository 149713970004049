<template>
    <div class="filtro-mentores formulario">
        <h2>Filtro:</h2>

        <div class="contenedor-input">
            <div class="toggle">
                <input type="checkbox" id="mentores-sugeridos" v-model="data.sugeridos" @change="emitFiltro"/>
                <label for="mentores-sugeridos">
                    Mentores sugeridos
                    <popup-info :data="'mentores-sugeridos'"></popup-info>
                </label>
            </div>
        </div>

        <div class="contenedor-multiselect" v-if="programas && programas.length != 0">
            <h4 class="help">Selecciona para filtrar por programas</h4>

            <multiselect
                :multiple="true"
                :track-by="'name'"
                :label="'name'"
                v-model="data.programas.search"
                :options="programas"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Programas"
            ></multiselect>
        </div>

        <div class="contenedor-multiselect" v-if="domains.area && domains.area.length != 0">
            <h4 class="help">Selecciona para filtrar por áreas</h4>
            <multiselect
                :multiple="true"
                :track-by="'text'"
                :label="'text'"
                v-model="data.area.search"
                :options="domains.area"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Áreas profesionales"
            ></multiselect>
        </div>

        <div class="contenedor-multiselect" v-if="sectores && data.area.search.length != 0">
            <h4 class="help">Selecciona para filtrar por sectores</h4>
            <multiselect
            :multiple="true"
            :track-by="'text'"
            :label="'text'"
            v-model="data.sector.search"
            :options="sectores"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Sectores profesionales"
        ></multiselect>
        </div>

        <div class="contenedor-multiselect" v-if="domains.language && domains.language.length != 0">
            <h4 class="help">Selecciona para filtrar por idiomas</h4>
            <multiselect
                :multiple="true"
                :track-by="'text'"
                :label="'text'"
                v-model="data.language.search"
                :options="domains.language"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Idioma"
            ></multiselect>
        </div>

        <div class="contenedor-input">
            <label class="help" for="titulacion">Titulación</label>
            <input type="text" v-model="data.titulacion.search" name="titulacion" id="titulacion" @keyup.enter="emitFiltro" />
        </div>

        <div class="contenedor-input">
            <label class="help" for="empresa">Ubicación</label>
            <input type="text" v-model="data.ubicacion.search" name="keywords" id="keywords" @keyup.enter="emitFiltro" />
        </div>

        <div class="contenedor-input">
            <label class="help" for="empresa">Empresa</label>
            <input type="text" v-model="data.profesional_search.search" name="company" id="company" @keyup.enter="emitFiltro" />
        </div>

        <div class="contenedor-input">
            <label class="help" for="empresa">Buscador</label>
            <input type="text" v-model="data.textFilter" name="textFilter" id="textFilter" @keyup.enter="emitFiltro" />
        </div>

        <div class="contenedor-radios">
            <h4 class="help">Modalidad de mentoría</h4>

            <div class="checkbox">
                <input type="checkbox" v-model="data.preferences.search" name="prensecial" id="presencial-si" value="0" @click="checkmixto" />
                <label for="presencial-si">Presencial</label>
            </div>

            <div class="checkbox">
                <input type="checkbox" v-model="data.preferences.search" name="presencial" id="presencial-no" value="1" @click="checkmixto" />
                <label for="presencial-no">Online</label>
            </div>

            <div class="checkbox">
                <input type="checkbox" v-model="data.preferences.search" name="presencial" id="presencial-mixto" value="2" @change="setMixto" />
                <label for="presencial-mixto">Indiferente</label>
            </div>
        </div>

        <a @click="emitFiltro" class="btn -small">Buscar</a>
        <a class="btn -small -skull-blue" @click="resetFilter">Limpiar filtro</a>

        <div class="busquedas">
            <h2>
                Búsquedas guardadas
                <popup-info :data="'busquedas-guardadas'"></popup-info>
            </h2>

            <a class="editar" @click="editsearchs = true" v-if="!editsearchs"></a>

            <template v-for="(busqueda,key) in user.queries">
                <a v-if="!editsearchs" @click="setBusqueda(busqueda.busqueda)" class="link-arrow" :key="key">{{ busqueda.name }} ({{ querieStats[busqueda.name] }})</a>
                <p v-if="editsearchs" :key="key">
                    <a class="erase" @click="eraseBusqueda(busqueda)">✕</a>
                    {{ busqueda.name }}
                </p>
            </template>

            <a v-if="!editsearchs" @click="showpopup = true" class="add-filtro">+ Añadir búsqueda</a>

            <a v-if="editsearchs" class="cerrar-edicion" @click="editsearchs = false">Cerrar edición</a>

            <div class="cover-guardar" v-if="showpopup">
                <div class="popup-guardar">
                    <p>Por favor indica a continuación el nombre que quieres darle a esta búsqueda.</p>
                    <div class="contenedor-input">
                        <input type="text" v-model="savename" name="keywords" id="keywords" focus />
                    </div>
                    <div class="contenedor-botones row-start">
                        <a :disabled="savename.length == 0" class="btn -small" @click="saveSearch">Guardar</a>
                        <a class="btn -skull-blue -small" @click="cancelSave">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'filtro-mentores',
    data: () => ({
        data: {
            //Type Text -> TextFilter ON
            //No Type -> TextFilter OFF
            sugeridos: false,
            programas: {
                name: 'active_programs',
                search: [],
            },
            preferences: {
                name: 'preferences_participation',
                search: [],
            },
            area: {
                name: 'area.name',
                search: [],
                objectFilter: true,
                type: 'text',
            },
            titulacion: {
                name: 'academics.name',
                search: '',
                textSearch: 1,
                type: 'text',
            },
            profesional_search: {
                name: 'profesional_search',
                search: '',
            },
            sector: {
                name: 'sector.name',
                search: [],
                objectFilter: true,
                type: 'text',
            },
            language: {
                name: 'language.name',
                search: [],
                objectFilter: true,
                type: 'text',
            },
            ubicacion: {
                name: 'full_place',
                search: '',
                type: 'text',
            },
            profesional_resumen: {
                name: 'profesional_resumen',
                search: '',
                textSearch: 1,
                type: 'text',
            },

            //Fakes filters, they dont have a filter
            //Is just for text filter
            preferences_motivation: {
                name: 'preferences_motivation',
                type: 'text',
                search: '',
            },
            current_position: {
                name: 'profesional_current',
                type: 'text',
                search: '',
            },
            profesional_position: {
                name: 'profesional.position',
                type: 'text',
                search: '',
            },
            abilities: {
                name: 'ability.name',
                type: 'text',
                search: '',
            },
            current_company: {
                name: 'profesional_current_company',
                search: '',
                type: 'text',
            },
            profesional_company: {
                name: 'profesional.company',
                type: 'text',
                search: '',
            },
            textFilter: ""
        },
        savename: '',
        showpopup: false,
        editsearchs: false,
        sectores: []
    }),
    computed: {
        ...mapGetters({
            domains: 'getDomains',
            programas: 'getOwnProgramas',
            user: 'getuser',
            querieStats: 'getquerieStats'
        }),
        profesional_company_search() {
            return this.current_company.search
        }
    },
    methods: {
        ...mapActions(['savebusquedamentores', 'requestErase', 'setAlert', 'requestsector']),
        saveSearch() {
            let data = {
                busqueda: this.data,
                name: this.savename
            }
            if (!this.user.queries || this.user.queries.length < 3) {
                this.savebusquedamentores(data).then(() => {
                    this.cancelSave();
                    this.resetFilter();
                });
            } else {
                this.setAlert(1012);
                this.cancelSave();
            }
        },
        setBusqueda(busqueda) {
            Object.assign(this.data, busqueda);
            this.emitFiltro();
        },
        cancelSave() {
            this.savename = '';
            this.showpopup = false;

        },
        eraseBusqueda(busqueda) {
            this.requestErase(busqueda);
        },
        emitFiltro() {
            this.$emit('setdata', this.data)
        },
        resetFilter() {
            
            this.data = Object.assign({}, this.default),
            
                this.data.area.search = []
                this.data.language.search = []
                this.data.sector.search = []
                this.data.programas.search = []

                this.data.profesional_search.search = "";
                this.data.ubicacion.search = "";
                this.data.titulacion.search = "";
                this.data.ubicacion.search = "";

                this.data.preferences.search = false;
                this.emitFiltro();

            //console.log(this.data)
        },
        setMixto() {
            if (this.data.preferences.search.includes('2')) {
                this.data.preferences.search = ['0', '1', '2'];
            }
        },
        checkmixto() {
            setTimeout(() => {
                if (this.data.preferences.search.length != 3) {
                    this.data.preferences.search = this.data.preferences.search.filter(e => e != '2');
                }
            }, 100)
        }
    },
    mounted() {
        this.default = Object.assign({}, this.data);
    },
    watch: {
        'data.area.search'() {
            this.requestsector(this.data.area.search).then(r => {
                this.sectores = r;
            })
        },
    }
}
</script>