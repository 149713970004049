<template>
    <div class="wrapper">
        <section class="encabezado -red-mentores">
            <h1>Encuentra un mentor/a</h1>
        </section>
        <section class="intro-text">
            <h2>Crea sinergias con otros profesionales UC3M</h2>
        </section>
        <div class="main-encuentra-mentor container row-center">
            <filtro @setdata="setData"></filtro>
            <section class="contenedor-mentores container row-start" v-if="!loading">
                <template v-if="ownprogramas && ownprogramas.length != 0">
                    <template v-if="data && data.length != 0">
                        <mentor-ficha :key="key" :data="n" v-for="(n,key) in data"></mentor-ficha>
                    </template>
                    <p v-else class="zero-results">No hay resultados para la búsqueda<br>o ya tienes una mentoría de este programa</p>
                </template>
                <div class="column-center programs-text" v-else>
                    <template v-if="requested">
                        <p class="zero-results">Para usar el buscador debes pertenecer a un programa, por favor contacta con nosotros.</p>
                        <router-link class="btn" @click.native="requestVisto" :to="$t('links.contacto')">Contactar</router-link>
                    </template>
                </div>
            </section>
            <div class="loading -small" v-else></div>
        </div>
    </div>
</template>
<script>

    import mentor from '@/components/mentor-card-to-mentee'
    import filtro from '@/components/filtro-mentores';
    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: 'red-mentores',
        mounted() {
            this.setData();

            setTimeout(() => {
                this.requested = true;
            },1000)
        },
        data : () => ({
            filtro : null,
            data : null,
            loading : false,
            requested : false
        }),
        components: {
            'mentor-ficha': mentor,
            'filtro': filtro
        },
        computed : {
          ...mapGetters({
            ownprogramas : 'getOwnProgramas',  
            user : 'getuser'
          })
        },
        methods: {
            ...mapActions(['requestMentores']),
            setData(filtro) {
                this.filtro = filtro;
                let data = (this.filtro) ? JSON.parse(JSON.stringify(this.filtro)) : null;
                this.loading = true;
                this.requestMentores(data).then(r => {
                    this.data = r;
                    this.loading = false;
                });
            }
        }
    }
</script>


<style lang="scss" scoped>
    .programs-text{
        width: 100%;
    }
</style>